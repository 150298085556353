<template>
<Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
<WhatsAppButton></WhatsAppButton>
  <div class="privacy-info-sec">
    <div class="container">
      <div class="">
          <div class="col-md-12 mb-5">
                    <template v-for="(data,index) in fetchCompanyPolicyData" :key="index">
                      <template v-if="data.stName=='return' &&  this.lang!='ar'">
                        <div class="mt-5"  v-html="data.stValue"></div>
                        
                      </template>
                       <template v-else-if="data.stName=='returnAr' &&  this.lang=='ar'">
                        <div class="mt-5"  v-html="data.stValue"></div>
                      </template>

                    </template>
                  
              
              
          </div>
         
      </div>
    </div>
    </div>
    
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import helper from "@/helper/helper";
import WhatsAppButton from "@/components/WhatsAppButton";
import {mapGetters} from 'vuex';
export default {
     name: "Returnpolicy",
     props: [ "changed_url",'translationloaded',
      'translate'],
      data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },  
        {
          path: "/return-policy",
          name: "cancelationAndRefundPolicy",
        },
      ],
       lang:'',
     
    };
  },
  components: {
    Breadcrumb,
    WhatsAppButton
   
  },
  mounted(){
       helper.backtoTop();
       this.lang = localStorage.getItem('lang');
  },
   computed:{
     ...mapGetters(['fetchCompanyPolicyData']),
  }
}
</script>
<style >
strong {
    font-weight: 800 !important;
}
    .privacy-info-sec h3{
        text-align: center;
        width:100%;
        margin-top: 30px;
    }
    .privacy-info-sec p {
    font-size: 1.6rem;
    line-height: 1.8em;
    color: #666;
    }
    .privacy-info-sec h4{
        margin-top: 20px;
    }
    .privacy-info-sec .privacy_ul{
        list-style-type: disc;
    }
    .privacy-info-sec .privacy_ul li{
        margin-left: 30px;
    }
    .privacy-info-sec .privacy_ol{
        list-style-type: lower-roman;
    }
    .privacy-info-sec .privacy_ol li{
         margin-left: 30px;
    }

</style>